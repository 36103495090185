import { useEffect, useState } from 'react'
import moment from 'moment'
import uuid from 'node-uuid'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import settings from '../../../../../settings'

function VBIOSLogs({ workerId, flashLogs }) {
  const [logs, setLogs] = useState([])
  const { t } = useTranslation('common')

  useEffect(() => {
    const eventSource = new EventSource(`${settings.backendUrl}/flash_logs/stream?worker_id=${workerId}`)

    eventSource.onmessage = event => {
      const newLog = JSON.parse(event.data)
      setLogs(prevLogs => [newLog, ...prevLogs])
    }

    return () => {
      eventSource.close()
    }
  }, [])

  useEffect(() => {
    setLogs(flashLogs)
  }, [flashLogs])

  const now = new Date().getTime()
  const getLogTime = timeData => {
    const time = Math.abs(timeData - now)
    let result, units
    if (time > 1000 * 60 * 60 * 24) {
      result = parseInt(time / (1000 * 60 * 60 * 24), 10)
      units = t('pages.workers.days')
    } else if (time > 1000 * 60 * 60) {
      result = parseInt(time / (1000 * 60 * 60), 10)
      units = 'h'
    } else if (time > 1000 * 60) {
      result = parseInt(time / (1000 * 60), 10)
      units = 'min'
    } else {
      result = parseInt(time / 1000, 10)
      units = 'sec'
    }
    return result + ' ' + units
  }

  return (
    <div className='form-control' style={{ maxHeight: '500px', resize: 'none' }}>
      <Row className='worker_charts'>
        <Col xs={12} className='worker-block worker-block_logs'>
          {logs ? (
            logs.map(log => {
              return (
                <div
                  key={uuid()}
                  className={`active-log__row ${
                    log.action.toLowerCase().includes('error') ? 'active-log__row_error' : ''
                  }`}
                >
                  <span className='active-log__time'>{moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                  <p
                    key={uuid()}
                    className={`active-log
                  ${
                    log.action.toLowerCase().includes('create') ||
                    log.action.toLowerCase().includes('run') ||
                    log.action.toLowerCase().includes('success')
                      ? 'green'
                      : log.action.toLowerCase().includes('error')
                      ? 'red'
                      : log.action.toLowerCase().includes('powerof') ||
                        log.action.toLowerCase().includes('reboot') ||
                        log.action.toLowerCase().includes('automatic worker software reset') ||
                        log.action.toLowerCase().includes('stop')
                      ? 'orange'
                      : log.action.toLowerCase().includes('submitted')
                      ? 'white'
                      : ''
                  }`}
                  >
                    {log.description ? (
                      <span className='active-log__descr'> {log.description ? log.description : ''}</span>
                    ) : (
                      ''
                    )}
                  </p>
                  <p className='active-log__time active-log__time_ago'>
                    {getLogTime(Date.parse(log.createdAt))} {'ago'}
                  </p>
                </div>
              )
            })
          ) : (
            <p>No logs found</p>
          )}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  flashLogs: store.rigs.flashLogsList,
})

export default connect(mapStateToProps, null)(VBIOSLogs)
