import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../../components/Table'
import * as actions from '../../actions'
import CoinActions from '../../components/CoinActions'
import { CreateCoinModal } from '../../components/modals'
import { Row, Col } from 'reactstrap'
import Cookies from 'js-cookie'

const Coins = props => {
  const [coins, setCoins] = useState([])
  const setCoinsList = props.setCoinsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 1000,
  )

  useEffect(() => {
    setCoinsList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setCoinsList, showCount, currentPage])

  useEffect(() => {
    const coinsArray = props.coinsList.map(coin => {
      return {
        ...coin,
        action: <CoinActions coin={coin} />,
      }
    })
    setCoins(coinsArray)
  }, [props.coinsList])

  const coinsData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Name',
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'nameSymbol',
        field: 'nameSymbol',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'units',
        field: 'units',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'Actions',
        field: 'action',
        width: 10,
      },
    ],
    rows: coins,
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <CreateCoinModal />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            name={'coins'}
            className={'dc__table'}
            responsive
            data={coinsData}
            total={props.coinsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
  coinsTotal: store.admin.coinsTotal,
})

const mapDispatchToProps = {
  setCoinsList: actions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Coins)
