export const types = {
  SET_RIGS_LIST: 'rigs/setList',
  SET_RIGS_DATA: 'rigs/setList2',
  OVERCLOCKING_CARDS: 'rigs/overlockingCard',
  DELETE_RIG: 'rigs/deleteRig',
  DELETE_CARD: 'rigs/deleteCard',
  CREATE_RIG: 'rigs/createRig',
  UPDATE_RIG: 'rigs/updateRig',
  SET_ACTION_PRESET_RIG: 'rigs/setRigActionPreset',
  REBOOT_POWEROF_RIG: 'rigs/setPowerOfRebootRig',
  RIG_LOGS_LIST: 'rigs/getRigLogs',
  DELETE_LOGS: 'rigs/deleteRigLogs',
  CREATE_RIG_SETTINGS: 'rigs/createRigSettings',
  UPDATE_RIG_SETTINGS: 'rigs/updateRigSettings',
  SET_ACTION_RIGS: 'rigs/setRigsAction',
  UPDATE_RIGERROR: 'rigs/updateRigError',
  WORKER_UPGRADE: 'rigs/downloadUpdates',
  SET_RIG_STATS: 'rigs/setRigStats',
  SET_OVERCLOCKING_DATA: 'rigs/setOverclockingData',
  SET_WORKER_INFORMATION: 'rigs/getRig',
  RIG_ERRORS_LIST: 'rigs/getRigErrors',
  BLOCK_RIGS_UPDATE: 'blockRigsUpdate',
  DELAY_RIG_UPDATE: 'delayRigUpdate',
  SET_VERSIONS: 'setOsDriverVersions',
  SET_RIGS_STATS: 'rigs/setRigsStats',
  OFF_MINING_CARD: 'rig/offMiningCard',
  SET_CONSOLE: 'rig/setConsole',
  GET_CONSOLE_DATA: 'rig/getConsoleData',
  SET_WORKER_TIME: 'rig/getTime',
  SET_WORKERS_MPS: 'rig/getRigsMPs',
  SET_FILTER_APPLIED: 'rig/filterApplied',
  SET_RIGS_LIST_FILTERED: 'rigs/setListFiltered',
  SET_DOWNLOAD_RIGS: 'rigs/setDownloadRigs',
  SET_VBIOSS_LIST: 'rig/setVbiosList',
  UPDATE_VBIOSS_FILE: 'rig/updateVbiosFile',
  FLESH_LOGS_LIST: 'rigs/getFlashLogs',
}
