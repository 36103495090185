import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import SelectVBIOSModal from './SelectVBIOSModal'
import * as actions from '../../../../RigsData/actions'
import VBIOSLogs from './VBIOSLogs'

const VBIOSModal = ({ worker, cards, onCancel, flashCards, getFlashLogs, clearFlashLogs }) => {
  const { t } = useTranslation('common')
  const [selectedCards, setSelectedCards] = useState([])
  const [selectedVBIOS, setSelectedVBIOS] = useState('none file selected')
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [forceVBIOSFlash, setForceVBIOSFlash] = useState(false)

  useEffect(() => {
    if (worker.id) {
      getFlashLogs({
        page: 1,
        size: 100,
        filters: { workerId: +worker.id, action: 'Flash log' },
      })
    }

    return () => {
      clearFlashLogs()
    }
  }, [clearFlashLogs, getFlashLogs, worker.id])

  const toggleCardSelection = card => {
    setSelectedCards(prev => {
      const isSelected = prev.some(c => c.id === card.id)
      if (isSelected) {
        return prev.filter(c => c.id !== card.id)
      } else {
        return [...prev, card]
      }
    })
  }

  const handleFlash = async () => {
    if (!selectedFile || selectedCards.length === 0) return

    try {
      await flashCards({
        vbiosId: selectedFile.id,
        cardsIds: selectedCards.map(card => +card.id),
        forceFlash: forceVBIOSFlash,
      })
    } catch (error) {
      console.error('Error flashing VBIOS:', error)
    }
  }

  return (
    <Modal isOpen={true} toggle={onCancel} size='xl'>
      <ModalHeader toggle={onCancel} className='position-relative'>
        <div className='w-100 text-center position-absolute' style={{ left: 0 }}>
          <h3 className='mb-0'>VBIOS Flash Tool</h3>
        </div>
      </ModalHeader>

      <ModalBody>
        <div className='d-flex justify-content-end align-items-center mb-3'>
          <h6 className='mb-0' style={{ marginRight: '20px' }}>
            Selected VBIOS: <span style={{ color: selectedFile ? 'green' : '#ec4561' }}>{selectedVBIOS}</span>
          </h6>
          <Button color='primary' size='sm' style={{ marginLeft: '20px' }} onClick={() => setIsSelectModalOpen(true)}>
            Select VBIOS
          </Button>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <ListGroup
              style={{
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              {cards.map(card => (
                <ListGroupItem
                  key={card.id}
                  active={selectedCards.some(c => c.id === card.id)}
                  onClick={() => toggleCardSelection(card)}
                  className='d-flex align-items-center position-relative'
                  style={{
                    cursor: 'pointer',
                    backgroundColor: selectedCards.some(c => c.id === card.id) ? '#435B71' : '#2C3B4A',
                    borderColor: '#435B71',
                    color: '#fff',
                    padding: '10px 15px',
                  }}
                >
                  {selectedCards.some(c => c.id === card.id) && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: '#4CAF50',
                        fontSize: '20px',
                      }}
                    >
                      ✓
                    </div>
                  )}
                  <td className='card__info'>
                    <div className='card__num'>
                      {t('pages.workers.GPU')}
                      {card.detected === false || card.uuid === 'GPUx' ? '' : card.cardNum}
                    </div>
                    <div>
                      {card.vendorName && <span className='card__vendor'>{card.vendorName + ' '}</span>}
                      <span
                        className={`card__name
                         ${card.cardType === 'amd' ? 'card__name_amd' : ''}`}
                      >
                        {card.gpuName + ' '}
                        <span className='card__vendor'>{`${
                          !card.vram.includes('M') ? card.vram + ' Mb' : card.vram
                        }`}</span>
                        {<br />}
                      </span>

                      {card.fwInfo && <>{card.fwInfo}</>}
                      {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
                      {card.memType && <span className='card__memType'> {card.memType}</span>}
                      {card.detected === false ? <span className='card__off'>(OFFLINE)</span> : ''}
                      {(!card.gpuTemp && !card.powerInfo && card.detected) || card.uuid === 'GPUx' ? (
                        <span className='card__off'>(FAULT)</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
          <div className='col-md-6'>
            <VBIOSLogs workerId={worker.id} />
          </div>
        </div>
      </ModalBody>

      <ModalFooter className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            className='form-check-input'
            id='selectAll'
            checked={selectedCards.length === cards.length}
            onChange={e => {
              if (e.target.checked) {
                setSelectedCards(cards)
              } else {
                setSelectedCards([])
              }
            }}
            style={{ marginRight: '10px' }}
          />
          <label className='form-check-label mb-0' htmlFor='selectAll' style={{ marginRight: '20px' }}>
            Select All Cards
          </label>

          <input
            type='checkbox'
            className='form-check-input'
            id='forceFlash'
            checked={forceVBIOSFlash}
            onChange={e => setForceVBIOSFlash(e.target.checked)}
            style={{ marginRight: '10px' }}
          />
          <label className='form-check-label mb-0' htmlFor='forceFlash'>
            Force VBIOS Flash (Use at your own risk)
          </label>
        </div>
        <div>
          <Button
            color='primary'
            style={{ marginRight: '20px' }}
            onClick={handleFlash}
            disabled={selectedCards.length === 0}
          >
            Flash
          </Button>
          <Button color='secondary' onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </ModalFooter>

      <SelectVBIOSModal
        isOpen={isSelectModalOpen}
        toggle={() => setIsSelectModalOpen(false)}
        onSelectVBIOS={vbiosName => setSelectedVBIOS(vbiosName)}
        onSelectFile={file => {
          setSelectedFile(file)
        }}
        workerId={worker.id}
      />
    </Modal>
  )
}

const mapDispatchToProps = {
  flashCards: actions.flashCards,
  getFlashLogs: actions.getFlashLogs,
  clearFlashLogs: actions.clearFlashLogs,
}

export default connect(null, mapDispatchToProps)(VBIOSModal)
