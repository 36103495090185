import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UiRangeSlider from '../../../../components/UI/RangeSlider'
import RangeSliderSpecial from '../../../../components/UI/RangeSliderSpecial'
import * as actions from '../../../RigsData/actions'
import * as actionsProfiles from '../../../OverclockingProfiles/actions'
import CheckGpuErrorMessage from './CheckGpuErrorMessage'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import Switch from 'react-switch'
import GPUBLockMobile from './GPUBLockMobile'
import GPUBLockMobileSelect from './GPUBLockMobileSelect'
import SaveProfileDialog from './SaveProfileDialog'

const OverclockingTab = ({ rig, toggle, ...props }) => {
  const { t } = useTranslation('common')
  const [advancedPage, setAdvancedPage] = useState(rig && rig.overclockingPresetType === 'advanced')
  const [gpuModal, setGpuModal] = useState(false)
  const [gpuProfileModal, setGpuProfileModal] = useState(false)
  const [cardErrors, setErrorStrings] = useState([])
  const [showCardsErrorAlert, setShowCardsErrorAlert] = useState(false)
  const [showCardsErrorAlertProfile, setShowCardsErrorAlertProfile] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [coreClock, setCoreClock] = useState('')
  const [memoryClock, setMemoryClock] = useState('')
  const [powerLimit, setPowerLimit] = useState('')
  const [fan, setFan] = useState(60)
  const [coreClockAMD, setCoreClockAMD] = useState('')
  const [memoryClockAMD, setMemoryClockAMD] = useState('')

  const [socFrequency, setSocFrequency] = useState('')
  const [memControlVoltage, setmemControlVoltage] = useState('')
  const [socVDDmax, setsocVDDmax] = useState('')

  const [coreVddcAMD, setCoreVddcAMD] = useState('')
  const [memoryVddcAMD, setMemoryVddcAMD] = useState('')
  const [fanAMD, setFanAMD] = useState(60)
  const [action, setAction] = useState('on_fly')
  const [dpm, setDPM] = useState('2')
  const [mdpm, setMDPM] = useState('1')
  const [videoCards, setVideoCards] = useState([])
  const [nvidiaCards, setNvidiaCards] = useState([])
  const [amdCards, setAmdCards] = useState([])

  const [haveAMD1000, setHaveAMD1000] = useState(false)
  const [differentCards, setDifferentCards] = useState(false)
  const [differentManufacturer, setDifferentManufacturer] = useState(false)
  const [manufacturer, setManufacturer] = useState(false)

  useEffect(() => {
    const videoCardsNames = []
    if (rig && rig.videoCards && rig.videoCards.length > 0) {
      setVideoCards(
        rig.videoCards.map(card => {
          if (
            videoCardsNames.length === 0 ||
            (videoCardsNames.length > 0 && videoCardsNames[0] !== card.gpuName.toLowerCase())
          )
            videoCardsNames.push(card.gpuName.toLowerCase())
          if (card.cardType.toLowerCase() === 'amd') {
            if (
              card.gpuName.includes('5300') ||
              card.gpuName.includes('5500') ||
              card.gpuName.includes('5600') ||
              card.gpuName.includes('5700') ||
              card.gpuName.includes('6300') ||
              card.gpuName.includes('6400') ||
              card.gpuName.includes('6500') ||
              card.gpuName.includes('6600') ||
              card.gpuName.includes('6650') ||
              card.gpuName.includes('6700') ||
              card.gpuName.includes('6750') ||
              card.gpuName.includes('6800') ||
              card.gpuName.includes('6900') ||
              card.gpuName.includes('6950')
            )
              setHaveAMD1000(true)
            return {
              ...card,
              fan: card.fan ? card.fan : 60,
              dpm: card.dpm ? card.dpm : 2,
            }
          } else {
            return {
              ...card,
              fan: card.fan ? card.fan : 60,
            }
          }
        }),
      )

      if (videoCardsNames.length > 1) setDifferentCards(true)
      const nvidia = []
      const amd = []
      rig.videoCards.forEach(card => {
        if (card.cardType === 'nvidia') {
          nvidia.push(card.id)
          setNvidiaCards(nvidia)
        } else if (card.cardType === 'amd') {
          amd.push(card.id)
          setAmdCards(amd)
        }
      })
      if (nvidia.length > 0 && amd.length > 0) setDifferentManufacturer(true)
    }

    if (rig && rig.overAction) {
      setAction(rig.overAction)
    }

    if (
      rig &&
      rig.overclockingAllCardsData &&
      rig.videoCards.length > 0 &&
      (rig.overclockingAllCardsData.gpuClock !== null || rig.overclockingAllCardsData.powerLimit !== null)
    ) {
      setCoreClock(rig.overclockingAllCardsData.gpuClock || '')
      setCoreClockAMD(rig.overclockingAllCardsData.gpuClock || '')
      setMemoryClock(rig.overclockingAllCardsData.memClock || '')
      setMemoryClockAMD(rig.overclockingAllCardsData.memClock || '')
      setPowerLimit(rig.overclockingAllCardsData.powerLimit || '')
      setMemoryVddcAMD(rig.overclockingAllCardsData.memVddc || '')
      setCoreVddcAMD(rig.overclockingAllCardsData.coreVddc || '')
      setSocFrequency(rig.overclockingAllCardsData.socFrequency || '')
      setmemControlVoltage(rig.overclockingAllCardsData.memControlVoltage || '')
      setsocVDDmax(rig.overclockingAllCardsData.socVDDmax || '')
      setFan(rig.overclockingAllCardsData.fan || 60)
      setFanAMD(rig.overclockingAllCardsData.fan || 60)
      setDPM(rig.overclockingAllCardsData.dpm || 2)
      setMDPM(rig.overclockingAllCardsData.mdpm || '')
      setAlgorithm(
        rig.overclockingAlgo
          ? {
              label: rig.overclockingAlgo,
              value: rig.overclockingAlgo,
              coins:
                props.algoCoinsList &&
                props.algoCoinsList.find(algo => algo.algo === rig.overclockingAlgo) &&
                props.algoCoinsList.find(algo => algo.algo === rig.overclockingAlgo).coins,
            }
          : null,
      )
    }
    if (rig.amdmemorytweak) {
      setAmdmemorytweak(true)
      setAmdmemorytweakString2(rig.amdmemorytweak)
      setAmdmemorytweakString(rig.amdmemorytweak)
    } else {
      setAmdmemorytweak(false)
    }
    if (rig.onGodAnEthArgPill) {
      setOhGodAnETHlagementPill(true)
      setOhGodAnETHlagementPillString(rig.onGodAnEthArgPill)
      setOhGodAnETHlagementPillString2(rig.onGodAnEthArgPill)
    } else {
      setOhGodAnETHlagementPill(false)
    }
    if (rig.autoTune) {
      setAutoTune(true)
      setAutoTuneEditString(rig.autoTune)
      setAutoTuneEditString2(rig.autoTune)
    } else {
      setAutoTune(false)
    }
    if (rig.p0) {
      setP0(true)
    } else {
      setP0(false)
    }

    setCardsForEach([])
    setAdvancedPage(rig && rig.overclockingPresetType === 'advanced')
    //console.log(rig && rig.overclockingPresetType === 'advanced')
    setTabIndex(rig && rig.overclockingPresetType === 'advanced' ? 1 : 0)
    setLocked(rig && rig.overclockingPresetType === 'advanced')
  }, [props.algoCoinsList, rig])

  const toggleGpuErrMess = () => {
    setGpuModal(!gpuModal)
  }
  const toggleGpuErrMessProfile = () => {
    setGpuProfileModal(!gpuProfileModal)
  }

  const setApplyAction = e => {
    setAction(e.target.value)
  }

  const [cardsForEach, setCardsForEach] = useState([])

  const checkCardsFunc = (val, card, param) => {
    const checkCard = cardsForEach.find(c => c.id === card.id)
    if (!checkCard) {
      setCardsForEach([...cardsForEach, card])
    } else {
      const updatedCards = cardsForEach.map(c => {
        if (c.id === card.id) c[param] = val
        return c
      })
      setCardsForEach(updatedCards)
    }
    //console.log(cardsForEach)
  }

  const setCoreClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'gpuClock')
    card.gpuClock = value
  }
  const setMemoryClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memClock')
    card.memClock = value
  }
  const setPowerLimitSpecial = (value, card) => {
    checkCardsFunc(value, card, 'powerLimit')
    card.powerLimit = value
  }
  const setFanSpecial = (value, card) => {
    checkCardsFunc(value, card, 'fan')
    card.fan = value
  }
  const setCoreVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'coreVddc')
    card.coreVddc = value
  }
  const setMemVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memVddc')
    card.memVddc = value
  }
  const setDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'dpm')
    card.dpm = event.target.value
  }

  const setSocFrequencySpecial = (value, card) => {
    checkCardsFunc(value, card, 'socFrequency')
    card.socFrequency = value
  }

  const setmemControlVoltageSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memControlVoltage')
    card.memControlVoltage = value
  }
  const setsocVDDmaxSpecial = (value, card) => {
    checkCardsFunc(value, card, 'socVDDmax')
    card.socVDDmax = value
  }
  const setMDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'mdpm')
    card.mdpm = event.target.value
  }

  const TabsChange = index => {
    setTabIndex(index)
    if (index === 1) {
      setAdvancedPage(true)
    }
    if (index === 0) {
      setAdvancedPage(false)
    }
  }

  const overlockingGeneral = async () => {
    if (rig.videoCards[0]?.cardType === 'amd' || rig.videoCards[1]?.cardType === 'amd') {
      console.log(memoryClockAMD)
      await props.overclocking({
        nvidiaCards: nvidiaCards.toString(),
        amdCards: amdCards.toString(),
        memClockAMD: memoryClockAMD ? memoryClockAMD.toString() : null,
        gpuClockAMD: coreClockAMD.toString(),
        fanAMD: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        p0: p0 === true ? p0 : false,
      })
      if (videoCards)
        for (let i = 0; i < videoCards.length; i++) {
          videoCards[i].gpuClock = coreClockAMD
          videoCards[i].memClock = memoryClockAMD
          videoCards[i].fan = fanAMD
          videoCards[i].coreVddc = coreVddcAMD
          videoCards[i].memVddc = memoryVddcAMD
          videoCards[i].dpm = dpm
          videoCards[i].mdpm = mdpm
        }
    }
    if (rig.videoCards[0]?.cardType === 'nvidia' || rig.videoCards[1]?.cardType === 'nvidia') {
      await props.overclocking({
        nvidiaCards: nvidiaCards.toString(),
        amdCards: amdCards.toString(),
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        p0: p0 === true ? p0 : false,
      })
      if (videoCards)
        for (let i = 0; i < videoCards.length; i++) {
          videoCards[i].powerLimit = powerLimit
          videoCards[i].gpuClock = coreClock
          videoCards[i].memClock = memoryClock
          videoCards[i].fan = fan
        }
    }
  }
  const overlockingAdvanced = async () => {
    const cards = []
    cardsForEach.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          id: card.id,
          rigId: rig.id,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm && card.dpm > 0 ? card.dpm.toString() : '',
          mdpm: card.mdpm && card.mdpm > 0 ? card.mdpm.toString() : '',
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
        })
      } else {
        cards.push({
          id: card.id,
          rigId: rig.id,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
        })
      }
    })

    await props.overclockingEach({
      action: action,
      cards: cards,
      rigId: rig.id,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      p0: p0 === true ? p0 : false,
    })
  }

  const overlockingCard = async () => {
    if (advancedPage === true || locked) {
      await overlockingAdvanced()
      toggle()
    } else {
      const needsCards = []
      rig.videoCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        setGpuModal(true)
      } else {
        await overlockingGeneral()
        toggle()
      }
    }
  }

  const answerYesSetForGpuCheck = async () => {
    await overlockingGeneral()
    setGpuModal(!gpuModal)
    toggle()
  }
  const answerYesSetForGpuCheckProfile = async () => {
    await SaveProfileGeneral(profileName, profileType, slot)
    setGpuProfileModal(!gpuProfileModal)
  }

  const [algorithm, setAlgorithm] = useState('')
  const [locked, setLocked] = useState(rig && rig.overclockingPresetType === 'advanced')
  const [amdmemorytweak, setAmdmemorytweak] = useState(false)
  const [amdmemorytweakEdit, setAmdmemorytweakEdit] = useState(false)
  const [amdmemorytweakString, setAmdmemorytweakString] = useState('amdmemorytweak --REF 30')
  const [amdmemorytweakString2, setAmdmemorytweakString2] = useState('amdmemorytweak --REF 30')
  const [ohGodAnETHlagementPill, setOhGodAnETHlagementPill] = useState(false)
  const [ohGodAnETHlagementPillEdit, setOhGodAnETHlagementPillEdit] = useState(false)
  const [ohGodAnETHlagementPillString, setOhGodAnETHlagementPillString] = useState('OhGodAnETHlagement')
  const [ohGodAnETHlagementPillString2, setOhGodAnETHlagementPillString2] = useState('OhGodAnETHlagement')

  const [autoTune, setAutoTune] = useState(false)
  const [p0, setP0] = useState(false)
  const [autoTuneEdit, setAutoTuneEdit] = useState(false)
  const [autoTuneEditString, setAutoTuneEditString] = useState('')
  const [autoTuneEditString2, setAutoTuneEditString2] = useState('')

  const setAlgo = option => {
    setAlgorithm(option)
  }

  const unlockGeneral = () => {
    setLocked(false)
    setAdvancedPage(false)
  }
  const resetAmdmemorytweak = () => {
    setAmdmemorytweakString2(amdmemorytweakString)
    setAmdmemorytweakEdit(false)
  }
  const saveAmdmemorytweak = () => {
    setAmdmemorytweakString(amdmemorytweakString2)
    setAmdmemorytweakEdit(false)
  }
  const resetOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString2(ohGodAnETHlagementPillString)
    setOhGodAnETHlagementPillEdit(false)
  }
  const saveOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString(ohGodAnETHlagementPillString2)
    setOhGodAnETHlagementPillEdit(false)
  }

  const resetAutoTune = () => {
    setAutoTuneEditString2(autoTuneEditString)
    setAutoTuneEdit(false)
  }
  const saveAutoTune = () => {
    setAutoTuneEditString(autoTuneEditString2)
    setAutoTuneEdit(false)
  }

  const toggleSaveDialog = () => {
    setShowSaveDefault(!showSaveDefault)
  }

  const [profileName, setProfileName] = useState('')
  const [profileType, setProfileType] = useState(null)
  const [slot, setSlot] = useState(null)

  const SaveProfile = async (profileName = '', profileType, slot = null) => {
    setProfileName(profileName)
    setProfileType(profileType)
    setSlot(slot)
    if (advancedPage === true || locked) {
      await SaveProfileAdvanced(profileName, profileType, slot)
      setShowSaveDefault(false)
    } else {
      const needsCards = []
      rig.videoCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        setGpuProfileModal(true)
      } else {
        await SaveProfileGeneral(profileName, profileType, slot)
        setShowSaveDefault(false)
      }
    }
  }

  const SaveProfileAdvanced = async (profileName, profileType, slot) => {
    const cards = []

    videoCards.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          cardId: card.id,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm.toString(),
          mdpm: card.mdpm.toString(),
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      } else {
        cards.push({
          cardId: card.id,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      }
    })

    await props.saveOverclockingProfile({
      rigId: rig.id,
      name: profileName,
      type: 'advanced',
      profileType: profileType,
      slotId: slot,
      action,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      overclockingCards: cards,
      p0: p0 === true ? p0 : false,
    })
    await props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }

  const SaveProfileGeneral = async (profileName, profileType, slot) => {
    if (rig.videoCards[0]?.cardType === 'amd' || rig.videoCards[1]?.cardType === 'amd') {
      const res = await props.saveOverclockingProfile({
        rigId: rig.id,
        name: profileName,
        type: 'general',
        profileType: profileType,
        slotId: slot,
        cardType: 'amd',
        gpuName: rig.videoCards[0].gpuName,
        memClock: memoryClockAMD.toString(),
        gpuClock: coreClockAMD.toString(),
        fan: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
    }
    if (rig.videoCards[0]?.cardType === 'nvidia' || rig.videoCards[1]?.cardType === 'nvidia') {
      const res = await props.saveOverclockingProfile({
        rigId: rig.id,
        name: profileName,
        profileType: profileType,
        slotId: slot,
        type: 'general',
        cardType: 'nvidia',
        gpuName: rig.videoCards[0].gpuName,
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
    }
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }

  const checkWhatClicked = e => {
    setLastClickedElement(e.target)
  }

  const [showAlertOverClock, setShowAlertOverClock] = useState(false)
  const [showSaveDefault, setShowSaveDefault] = useState(false)
  const [lastClickedElement, setLastClickedElement] = useState(null)
  const [lastClickedElementClickCount, setLastClickedElementClickCount] = useState(0)
  const [activeCard, setActiveCard] = useState(null)
  const [activeCardNumber, setActiveCardNumber] = useState(0)
  const [activeCardType, setActiveCardType] = useState('')
  const doubleCardUp = (e, card, number, property) => {
    number--
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number - clickCount
    if (sum > -1) {
      const videoCard = videoCards.filter(videoCard => videoCard.cardType === card.cardType)[sum]
      if (videoCard) {
        if (property === 'gpuClock') {
          videoCard.gpuClock = card.gpuClock
          checkCardsFunc(card.gpuClock, videoCard, 'gpuClock')
        }
        if (property === 'memClock') {
          videoCard.memClock = card.memClock
          checkCardsFunc(card.memClock, videoCard, 'memClock')
        }
        if (property === 'coreVddc') {
          videoCard.coreVddc = card.coreVddc
          checkCardsFunc(card.coreVddc, videoCard, 'coreVddc')
        }
        if (property === 'fan') {
          videoCard.fan = card.fan
          checkCardsFunc(card.fan, videoCard, 'fan')
        }
        if (property === 'dpm') {
          videoCard.dpm = card.dpm
          checkCardsFunc(card.dpm, videoCard, 'dpm')
        }
        if (property === 'mdpm') {
          videoCard.mdpm = card.mdpm
          checkCardsFunc(card.mdpm, videoCard, 'mdpm')
        }
        if (property === 'socFrequency') {
          videoCard.socFrequency = card.socFrequency
          checkCardsFunc(card.socFrequency, videoCard, 'socFrequency')
        }
        if (property === 'memControlVoltage') {
          videoCard.memControlVoltage = card.memControlVoltage
          checkCardsFunc(card.memControlVoltage, videoCard, 'memControlVoltage')
        }
        if (property === 'socVDDmax') {
          videoCard.socVDDmax = card.socVDDmax
          checkCardsFunc(card.socVDDmax, videoCard, 'socVDDmax')
        }
        if (property === 'powerLimit') {
          videoCard.powerLimit = card.powerLimit
          checkCardsFunc(card.powerLimit, videoCard, 'powerLimit')
        }
        if (property === 'memVddc') {
          videoCard.memVddc = card.memVddc
          checkCardsFunc(card.memVddc, videoCard, 'memVddc')
        }
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const doubleCardDown = (e, card, number, property) => {
    number++
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number + +clickCount
    if (videoCards && sum <= videoCards.length) {
      const videoCard = videoCards.filter(videoCard => videoCard.cardType === card.cardType)[sum]
      if (videoCard) {
        if (property === 'gpuClock') {
          videoCard.gpuClock = card.gpuClock
          checkCardsFunc(card.gpuClock, videoCard, 'gpuClock')
        }
        if (property === 'memClock') {
          videoCard.memClock = card.memClock
          checkCardsFunc(card.memClock, videoCard, 'memClock')
        }
        if (property === 'coreVddc') {
          videoCard.coreVddc = card.coreVddc
          checkCardsFunc(card.coreVddc, videoCard, 'coreVddc')
        }
        if (property === 'fan') {
          videoCard.fan = card.fan
          checkCardsFunc(card.fan, videoCard, 'fan')
        }
        if (property === 'dpm') {
          videoCard.dpm = card.dpm
          checkCardsFunc(card.dpm, videoCard, 'dpm')
        }
        if (property === 'mdpm') {
          videoCard.mdpm = card.mdpm
          checkCardsFunc(card.mdpm, videoCard, 'mdpm')
        }
        if (property === 'socFrequency') {
          videoCard.socFrequency = card.socFrequency
          checkCardsFunc(card.socFrequency, videoCard, 'socFrequency')
        }
        if (property === 'memControlVoltage') {
          videoCard.memControlVoltage = card.memControlVoltage
          checkCardsFunc(card.memControlVoltage, videoCard, 'memControlVoltage')
        }
        if (property === 'socVDDmax') {
          videoCard.socVDDmax = card.socVDDmax
          checkCardsFunc(card.socVDDmax, videoCard, 'socVDDmax')
        }
        if (property === 'powerLimit') {
          videoCard.powerLimit = card.powerLimit
          checkCardsFunc(card.powerLimit, videoCard, 'powerLimit')
        }
        if (property === 'memVddc') {
          videoCard.memVddc = card.memVddc
          checkCardsFunc(card.memVddc, videoCard, 'memVddc')
        }
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const checkOverclockingErrors = type => {
    if (advancedPage === true || locked) {
      const errorStrings = []
      setErrorStrings([])
      videoCards.map(card => {
        let errorString = ''
        if (!card.gpuClock || card.gpuClock.length <= 0)
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.coreClockError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreClockError'))
        if (!card.memClock || card.memClock.length <= 0)
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.memoryClockError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.memoryClockError'))
        if (card.cardType === 'amd' && (!card.coreVddc || card.coreVddc.length <= 0))
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.coreVDDCError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreVDDCError'))
        if (errorString.length > 0) errorStrings.push(errorString + ' ' + t('pages.workers.notSpecified'))
        return card
      })
      setErrorStrings(errorStrings)
      if (errorStrings.length > 0) {
        if (type === 'overclocking') {
          setShowCardsErrorAlert(true)
        } else {
          setShowCardsErrorAlertProfile(true)
        }
        return false
      }
      if (type === 'overclocking') {
        setShowAlertOverClock(true)
      } else {
        toggleSaveDialog()
      }
    } else {
      if (
        (isNaN(parseFloat(coreVddcAMD)) || isNaN(parseFloat(memoryClockAMD)) || isNaN(parseFloat(coreClockAMD))) &&
        (isNaN(parseFloat(memoryClock)) || isNaN(parseFloat(coreClock)))
      ) {
        setErrorStrings([t('pages.workers.somethingNotSpecified')])
        if (type === 'overclocking') {
          setShowCardsErrorAlert(true)
        } else {
          setShowCardsErrorAlertProfile(true)
        }
        return false
      } else {
        if (type === 'overclocking') {
          setShowAlertOverClock(true)
        } else {
          toggleSaveDialog()
        }
      }
    }
  }

  return (
    <>
      <SaveProfileDialog
        open={showSaveDefault}
        toggle={toggleSaveDialog}
        save={SaveProfile}
        advancedPage={advancedPage}
      />
      <div>
        <SweetAlert
          title={t('pages.workers.notAllParametersSpecified')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showCardsErrorAlert}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            setShowCardsErrorAlert(false)
            setShowAlertOverClock(true)
          }}
          onCancel={() => {
            setShowCardsErrorAlert(false)
          }}
          confirmBtnText={t('sweetAlerts.yes')}
          calcelBtnText={t('sweetAlerts.no')}
          showCloseButton={false}
        >
          <div className={'videoCards_errors'}>
            {cardErrors.map((error, key) => {
              return <div key={key}>{error}</div>
            })}
          </div>
          <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
        </SweetAlert>
        <SweetAlert
          title={t('pages.workers.notAllParametersSpecified')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showCardsErrorAlertProfile}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            setShowCardsErrorAlertProfile(false)
            toggleSaveDialog()
          }}
          onCancel={() => {
            setShowCardsErrorAlertProfile(false)
          }}
          confirmBtnText={t('sweetAlerts.yes')}
          calcelBtnText={t('sweetAlerts.no')}
          showCloseButton={false}
        >
          <div className={'videoCards_errors'}>
            {cardErrors.map((error, key) => {
              return <div key={key}>{error}</div>
            })}
          </div>
          <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
        </SweetAlert>

        <SweetAlert
          title={
            rig
              ? t('pages.workers.pleaseConfirmOverclockingRig') + ': ' + props.rigName
              : t('pages.workers.pleaseConfirmOverclockingRig') + '.'
          }
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertOverClock}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            overlockingCard()
            setShowAlertOverClock(false)
          }}
          onCancel={() => {
            setShowAlertOverClock(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        ></SweetAlert>
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.workers.defaultOverclockingProfileWorker')} - {props.rigName ? props.rigName : ''}
        </ModalHeader>
        <ModalBody>
          <CheckGpuErrorMessage
            gpuModal={gpuModal}
            toggleGpuErrMess={toggleGpuErrMess}
            answerYesSet={answerYesSetForGpuCheck}
          />
          <CheckGpuErrorMessage
            gpuModal={gpuProfileModal}
            toggleGpuErrMess={toggleGpuErrMessProfile}
            answerYesSet={answerYesSetForGpuCheckProfile}
          />
          <Tabs onSelect={TabsChange} selectedIndex={tabIndex}>
            <TabList className={'card_tabs'}>
              <Tab>{t('pages.workers.general')}</Tab>
              <Tab>{t('pages.workers.advanced')}</Tab>
              <Tab>{t('pages.workers.additional')}</Tab>
            </TabList>

            <TabPanel>
              <Form className={'overclocking-default'}>
                <div className={'description'}>{t('pages.workers.generalTip')}</div>

                {nvidiaCards && nvidiaCards.length > 0 && amdCards && amdCards.length === 0 ? (
                  <div className={'general_nvidia'}>
                    <h6>NVIDIA</h6>
                    <Row>
                      <Col>
                        <div className={'form-group advanced_card_field'}>
                          <Label>
                            {coreClock >= 500 ? t('pages.workers.lockCoreClock') : t('pages.workers.coreClockOffset')},{' '}
                            {t('pages.workers.Mhz')}
                          </Label>
                          <UiRangeSlider
                            default={coreClock}
                            setValue={setCoreClock}
                            min={-9999}
                            max={9999}
                            step={1}
                            disabled={locked}
                          />
                        </div>
                        <div className={'form-group advanced_card_field'}>
                          <Label>{t('pages.workers.memoryClock')}</Label>
                          <UiRangeSlider
                            default={memoryClock}
                            setValue={setMemoryClock}
                            min={-9999}
                            max={9999}
                            step={1}
                            disabled={locked}
                          />
                        </div>
                        <div className={'form-group advanced_card_field'}>
                          <Label>{t('pages.workers.powerLimit')}</Label>
                          <UiRangeSlider
                            default={powerLimit}
                            setValue={setPowerLimit}
                            min={0}
                            max={999}
                            step={1}
                            disabled={locked}
                          />
                        </div>
                        <div className={'form-group advanced_card_field'}>
                          <Label>{t('pages.workers.FAN')}</Label>
                          <UiRangeSlider
                            default={fan}
                            isFan={true}
                            setValue={setFan}
                            min={0}
                            max={100}
                            step={1}
                            disabled={locked}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : null}
                {amdCards && amdCards.length > 0 && nvidiaCards && nvidiaCards.length === 0 ? (
                  <div className={'general_amd'}>
                    <h6>AMD</h6>
                    <div>
                      {haveAMD1000 ? (
                        <div>
                          <Row className={'advanced_card_row'}>
                            <Col>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.coreClock')}</Label>
                                <UiRangeSlider
                                  default={coreClockAMD}
                                  setValue={setCoreClockAMD}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryClock')}</Label>
                                <UiRangeSlider
                                  default={memoryClockAMD}
                                  setValue={setMemoryClockAMD}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.coreVDDC')}</Label>
                                <UiRangeSlider
                                  default={coreVddcAMD}
                                  setValue={setCoreVddcAMD}
                                  min={0}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryVDDC')}</Label>
                                <UiRangeSlider
                                  default={memoryVddcAMD}
                                  setValue={setMemoryVddcAMD}
                                  min={0}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'advanced_card_row'}>
                            <Col>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.socFrequency')}</Label>
                                <UiRangeSlider
                                  default={socFrequency}
                                  setValue={setSocFrequency}
                                  min={0}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.soCVDDMax')}</Label>
                                <UiRangeSlider
                                  default={socVDDmax}
                                  setValue={setsocVDDmax}
                                  min={0}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryController')}</Label>
                                <UiRangeSlider
                                  default={memControlVoltage}
                                  setValue={setmemControlVoltage}
                                  min={0}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.FAN')}</Label>
                                <UiRangeSlider
                                  default={fanAMD}
                                  setValue={setFanAMD}
                                  isFan={true}
                                  min={0}
                                  max={100}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.DPM')}</Label>
                                <select
                                  className={'form-control'}
                                  onChange={event => setDPM(event.target.value)}
                                  defaultValue={dpm}
                                  disabled={locked}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                </select>
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.MDPM')}</Label>
                                <select
                                  className={'form-control'}
                                  onChange={event => setMDPM(event.target.value)}
                                  defaultValue={mdpm}
                                  disabled={locked}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <Row className={'advanced_card_row'}>
                          <Col>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.coreClock')}</Label>
                              <UiRangeSlider
                                default={coreClockAMD}
                                setValue={setCoreClockAMD}
                                min={-9999}
                                max={9999}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.memoryClock')}</Label>
                              <UiRangeSlider
                                default={memoryClockAMD}
                                setValue={setMemoryClockAMD}
                                min={-9999}
                                max={9999}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.coreVDDC')}</Label>
                              <UiRangeSlider
                                default={coreVddcAMD}
                                setValue={setCoreVddcAMD}
                                min={0}
                                max={9999}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.memoryVDDC')}</Label>
                              <UiRangeSlider
                                default={memoryVddcAMD}
                                setValue={setMemoryVddcAMD}
                                min={0}
                                max={9999}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.memoryController')}</Label>
                              <UiRangeSlider
                                default={memControlVoltage}
                                setValue={setmemControlVoltage}
                                min={0}
                                max={9999}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field advanced_card_field__select'}>
                              <Label>{t('pages.workers.FAN')}</Label>
                              <UiRangeSlider
                                default={fanAMD}
                                setValue={setFanAMD}
                                isFan={true}
                                min={0}
                                max={100}
                                step={1}
                                disabled={locked}
                              />
                            </div>
                            <div className={'form-group advanced_card_field advanced_card_field__select'}>
                              <Label>{t('pages.workers.DPM')}</Label>
                              <select
                                className={'form-control'}
                                onChange={event => setDPM(event.target.value)}
                                defaultValue={dpm}
                                disabled={locked}
                              >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                              </select>
                            </div>
                            <div className={'form-group advanced_card_field advanced_card_field__select'}>
                              <Label>{t('pages.workers.MDPM')}</Label>
                              <select
                                className={'form-control'}
                                onChange={event => setMDPM(event.target.value)}
                                defaultValue={mdpm}
                                disabled={locked}
                              >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                ) : null}
                <Row>
                  <Col sm={12} className={!locked ? 'hidden' : 'general-locked'}>
                    {differentCards
                      ? t('pages.workers.differentCardsDetected')
                      : t('pages.workers.individualOverclockingApplied')}{' '}
                    {t('pages.workers.generalOverclockingLocked')}!{' '}
                    {!differentCards ? (
                      <Button color='primary' onClick={unlockGeneral}>
                        {t('pages.workers.unlock')}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <Row className={'card_note'}>
                  <Col sm={12}>
                    <Trans i18nKey='pages.workers.generalNotes' ns={'common'}>
                      Note: General tab normally used to overclock similar cards at same settings.
                      <br />
                      Advanced tab used for overclocking each card separately with specific settings
                      <br />
                      Additional tab used for adding special parameters/Pills and select mining algorithm for specific
                      settings
                    </Trans>
                  </Col>
                </Row>
              </Form>
            </TabPanel>
            <TabPanel onClick={checkWhatClicked}>
              <Form className={'d-md-none advanced-mobile overclocking-advanced'}>
                <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                {differentManufacturer ? (
                  <div className={'selectManufacturer'}>
                    {t('pages.workers.AMDNVIDIACardsDetected')}
                    <br />
                    <Switch
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 10,
                            color: '#fff',
                            paddingRight: 10,
                          }}
                        >
                          {' '}
                          NVIDIA
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 10,
                            color: '#fff',
                            paddingLeft: 10,
                          }}
                        >
                          {' '}
                          AMD
                        </div>
                      }
                      onColor='#ff0000'
                      offColor='#008000'
                      onChange={() => {
                        setManufacturer(!manufacturer)
                      }}
                      checked={manufacturer}
                      height={20}
                      width={60}
                    />
                  </div>
                ) : null}

                {rig &&
                videoCards &&
                videoCards.length > 0 &&
                nvidiaCards &&
                nvidiaCards.length > 0 &&
                (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                  <div>
                    <h5>
                      {t('pages.workers.coreClock')}
                      {activeCardType === 'gpuClock' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'nvidia').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'nvidia')
                        .map((card, key) => {
                          if (!card.gpuClock) card.gpuClock = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.gpuClock}
                              key={card.id + 'gpuClock'}
                              setValueFunc={setCoreClockSpecial}
                              card={card}
                              min={-9999}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('gpuClock')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.memoryClock')}
                      {activeCardType === 'memClock' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'nvidia').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'nvidia')
                        .map((card, key) => {
                          if (!card.memClock) card.memClock = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.memClock}
                              key={card.id + 'memClock'}
                              setValueFunc={setMemoryClockSpecial}
                              card={card}
                              min={-9999}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('memClock')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.powerLimit')}
                      {activeCardType === 'powerLimit' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'nvidia').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'nvidia')
                        .map((card, key) => {
                          if (!card.powerLimit) card.powerLimit = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.powerLimit}
                              key={card.id + 'powerLimit'}
                              setValueFunc={setPowerLimitSpecial}
                              card={card}
                              min={0}
                              max={999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('powerLimit')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.FAN')}
                      {activeCardType === 'fan' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'nvidia').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'nvidia')
                        .map((card, key) => {
                          if (!card.fan) card.fan = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.fan}
                              key={card.id + 'fan'}
                              setValueFunc={setFanSpecial}
                              card={card}
                              isFan={true}
                              min={0}
                              max={100}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('fan')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                  </div>
                ) : null}

                {rig &&
                videoCards &&
                videoCards.length > 0 &&
                amdCards &&
                amdCards.length > 0 &&
                (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                  <div>
                    <h5>
                      {t('pages.workers.coreClock')}
                      {activeCardType === 'gpuClock' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.gpuClock) card.gpuClock = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.gpuClock}
                              key={card.id + 'gpuClock'}
                              setValueFunc={setCoreClockSpecial}
                              card={card}
                              min={-9999}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('gpuClock')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.memoryClock')}
                      {activeCardType === 'memClock' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.memClock) card.memClock = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.memClock}
                              key={card.id + 'memClock'}
                              setValueFunc={setMemoryClockSpecial}
                              card={card}
                              min={-9999}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('memClock')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.coreVDDC')}
                      {activeCardType === 'coreVddc' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.coreVddc) card.coreVddc = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.coreVddc}
                              key={card.id + 'coreVddc'}
                              setValueFunc={setCoreVddcSpecial}
                              card={card}
                              min={0}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('coreVddc')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.memoryVDDC')}
                      {activeCardType === 'memVddc' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.memVddc) card.memVddc = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.memVddc}
                              key={card.id + 'memVddc'}
                              setValueFunc={setMemVddcSpecial}
                              card={card}
                              min={0}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('memVddc')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.memoryController')}
                      {activeCardType === 'memControlVoltage' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.memControlVoltage) card.memControlVoltage = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.memControlVoltage}
                              key={card.id + 'memControlVoltage'}
                              setValueFunc={setmemControlVoltageSpecial}
                              card={card}
                              min={0}
                              max={9999}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('memControlVoltage')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    {haveAMD1000 && (
                      <>
                        <h5>
                          {t('pages.workers.socFrequency')}
                          {activeCardType === 'socFrequency' && (
                            <div className={'doubleArrows'}>
                              {activeCardNumber > 0 && (
                                <div
                                  className={'btn btn-primary doubleArrow'}
                                  onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                                >
                                  <i className='fas fa-chevron-left'></i>{' '}
                                  <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                    Copy to
                                    <br />
                                    the Left
                                  </Trans>
                                </div>
                              )}
                              {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                                <div
                                  className={'btn btn-primary doubleArrow'}
                                  onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                                >
                                  <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                    Copy to
                                    <br />
                                    the Right
                                  </Trans>
                                  <i className='fas fa-chevron-right'></i>
                                </div>
                              )}
                            </div>
                          )}
                        </h5>
                        <div className={'gpu-row'}>
                          {videoCards
                            .filter(
                              card =>
                                card.cardType === 'amd' &&
                                (card.gpuName.includes('5300') ||
                                  card.gpuName.includes('5500') ||
                                  card.gpuName.includes('5600') ||
                                  card.gpuName.includes('5700') ||
                                  card.gpuName.includes('6300') ||
                                  card.gpuName.includes('6400') ||
                                  card.gpuName.includes('6500') ||
                                  card.gpuName.includes('6600') ||
                                  card.gpuName.includes('6650') ||
                                  card.gpuName.includes('6700') ||
                                  card.gpuName.includes('6750') ||
                                  card.gpuName.includes('6800') ||
                                  card.gpuName.includes('6900') ||
                                  card.gpuName.includes('6950')),
                            )
                            .map((card, key) => {
                              if (!card.socFrequency) card.socFrequency = ''
                              return (
                                <GPUBLockMobile
                                  defaultValue={card.socFrequency}
                                  key={card.id + 'socFrequency'}
                                  setValueFunc={setSocFrequencySpecial}
                                  card={card}
                                  min={0}
                                  max={9999}
                                  disableDoubleButtons={() => {
                                    setActiveCardType('')
                                  }}
                                  onClick={() => {
                                    setActiveCardType('socFrequency')
                                    setActiveCard(card)
                                    setActiveCardNumber(key)
                                  }}
                                />
                              )
                            })}
                        </div>
                        <h5>
                          {t('pages.workers.soCVDDMax')}
                          {activeCardType === 'socVDDmax' && (
                            <div className={'doubleArrows'}>
                              {activeCardNumber > 0 && (
                                <div
                                  className={'btn btn-primary doubleArrow'}
                                  onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                                >
                                  <i className='fas fa-chevron-left'></i>{' '}
                                  <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                    Copy to
                                    <br />
                                    the Left
                                  </Trans>
                                </div>
                              )}
                              {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                                <div
                                  className={'btn btn-primary doubleArrow'}
                                  onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                                >
                                  <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                    Copy to
                                    <br />
                                    the Right
                                  </Trans>
                                  <i className='fas fa-chevron-right'></i>
                                </div>
                              )}
                            </div>
                          )}
                        </h5>
                        <div className={'gpu-row'}>
                          {videoCards
                            .filter(
                              card =>
                                card.cardType === 'amd' &&
                                (card.gpuName.includes('5300') ||
                                  card.gpuName.includes('5500') ||
                                  card.gpuName.includes('5600') ||
                                  card.gpuName.includes('5700') ||
                                  card.gpuName.includes('6300') ||
                                  card.gpuName.includes('6400') ||
                                  card.gpuName.includes('6500') ||
                                  card.gpuName.includes('6600') ||
                                  card.gpuName.includes('6650') ||
                                  card.gpuName.includes('6700') ||
                                  card.gpuName.includes('6750') ||
                                  card.gpuName.includes('6800') ||
                                  card.gpuName.includes('6900') ||
                                  card.gpuName.includes('6950')),
                            )
                            .map((card, key) => {
                              if (!card.socVDDmax) card.socVDDmax = ''
                              return (
                                <GPUBLockMobile
                                  defaultValue={card.socFrequency}
                                  key={card.id + 'socVDDmax'}
                                  setValueFunc={setsocVDDmaxSpecial}
                                  card={card}
                                  min={0}
                                  max={9999}
                                  disableDoubleButtons={() => {
                                    setActiveCardType('')
                                  }}
                                  onClick={() => {
                                    setActiveCardType('socVDDmax')
                                    setActiveCard(card)
                                    setActiveCardNumber(key)
                                  }}
                                />
                              )
                            })}
                        </div>
                      </>
                    )}
                    <h5>
                      {t('pages.workers.DPM')}
                      {activeCardType === 'dpm' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (
                            card.gpuName.includes('5300') ||
                            card.gpuName.includes('5500') ||
                            card.gpuName.includes('5600') ||
                            card.gpuName.includes('5700') ||
                            card.gpuName.includes('6300') ||
                            card.gpuName.includes('6400') ||
                            card.gpuName.includes('6500') ||
                            card.gpuName.includes('6600') ||
                            card.gpuName.includes('6650') ||
                            card.gpuName.includes('6700') ||
                            card.gpuName.includes('6750') ||
                            card.gpuName.includes('6800') ||
                            card.gpuName.includes('6900') ||
                            card.gpuName.includes('6950')
                          )
                            card.card1000series = true
                          if (!card.dpm) card.dpm = 1
                          return (
                            <GPUBLockMobileSelect
                              defaultValue={card.dpm}
                              key={card.id + 'dpm'}
                              setValueFunc={setDPMVddcSpecial}
                              card={card}
                              options={
                                card.card1000series
                                  ? [
                                      { value: 1, label: 1 },
                                      { value: 2, label: 2 },
                                    ]
                                  : [
                                      { value: 1, label: 1 },
                                      { value: 2, label: 2 },
                                      { value: 3, label: 3 },
                                      { value: 4, label: 4 },
                                      { value: 5, label: 5 },
                                      { value: 6, label: 6 },
                                      { value: 7, label: 7 },
                                    ]
                              }
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('dpm')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.MDPM')}
                      {activeCardType === 'mdpm' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.mdpm) card.mdpm = 1
                          return (
                            <GPUBLockMobileSelect
                              defaultValue={card.mdpm}
                              key={card.id + 'mdpm'}
                              setValueFunc={setMDPMVddcSpecial}
                              card={card}
                              options={[
                                { value: 1, label: 1 },
                                { value: 2, label: 2 },
                              ]}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('mdpm')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                    <h5>
                      {t('pages.workers.FAN')}
                      {activeCardType === 'fan' && (
                        <div className={'doubleArrows'}>
                          {activeCardNumber > 0 && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardUp(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <i className='fas fa-chevron-left'></i>{' '}
                              <Trans i18nKey='pages.workers.copyToLeft' ns={'common'}>
                                Copy to
                                <br />
                                the Left
                              </Trans>
                            </div>
                          )}
                          {activeCardNumber + 1 < videoCards.filter(card => card.cardType === 'amd').length && (
                            <div
                              className={'btn btn-primary doubleArrow'}
                              onClick={e => doubleCardDown(e, activeCard, activeCardNumber, activeCardType)}
                            >
                              <Trans i18nKey='pages.workers.copyToRight' ns={'common'}>
                                Copy to
                                <br />
                                the Right
                              </Trans>
                              <i className='fas fa-chevron-right'></i>
                            </div>
                          )}
                        </div>
                      )}
                    </h5>
                    <div className={'gpu-row'}>
                      {videoCards
                        .filter(card => card.cardType === 'amd')
                        .map((card, key) => {
                          if (!card.fan) card.fan = ''
                          return (
                            <GPUBLockMobile
                              defaultValue={card.fan}
                              key={card.id + 'fan'}
                              setValueFunc={setFanSpecial}
                              card={card}
                              isFan={true}
                              min={0}
                              max={100}
                              disableDoubleButtons={() => {
                                setActiveCardType('')
                              }}
                              onClick={() => {
                                setActiveCardType('fan')
                                setActiveCard(card)
                                setActiveCardNumber(key)
                              }}
                            />
                          )
                        })}
                    </div>
                  </div>
                ) : null}
              </Form>
              <Form className={'d-none d-md-block overclocking-advanced'}>
                <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                {differentManufacturer ? (
                  <div className={'selectManufacturer'}>
                    {t('pages.workers.AMDNVIDIACardsDetected')}
                    <br />
                    <Switch
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 10,
                            color: '#fff',
                            paddingRight: 10,
                          }}
                        >
                          {' '}
                          NVIDIA
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 10,
                            color: '#fff',
                            paddingLeft: 10,
                          }}
                        >
                          {' '}
                          AMD
                        </div>
                      }
                      onColor='#ff0000'
                      offColor='#008000'
                      onChange={() => {
                        setManufacturer(!manufacturer)
                      }}
                      checked={manufacturer}
                      height={20}
                      width={60}
                    />
                  </div>
                ) : null}
                {rig &&
                videoCards &&
                videoCards.length > 0 &&
                nvidiaCards &&
                nvidiaCards.length > 0 &&
                (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                  <div>
                    <Row className={'hide-on-mobile'}>
                      <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                      <Col sm={12} md={9}>
                        <Row className={'advanced_card_row'}>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.coreClock')}</Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.memoryClock')}</Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.powerLimit')}</Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.FAN')}</Label>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <div className={'detailed_cards'}>
                      {rig && videoCards && videoCards.length > 0 && videoCards.length > 0
                        ? videoCards
                            .filter(card => card.cardType === 'nvidia')
                            .map((card, key) => {
                              if (!card.gpuClock) card.gpuClock = ''
                              if (!card.memClock) card.memClock = ''
                              if (!card.powerLimit) card.powerLimit = ''
                              if (!card.fan) card.fan = ''
                              if (!card.coreVddc) card.coreVddc = ''
                              if (!card.memVddc) card.memVddc = ''
                              if (!card.dpm) card.dpm = 1
                              if (!card.mdpm) card.mdpm = 1

                              return (
                                <Row key={key}>
                                  <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                    <div className={'gpu_number'}>
                                      GPU{card.detected ? card.cardNum : ''} -{' '}
                                      {card.cardSlot ? card.cardSlot.substring(5) : ''} -{' '}
                                      <span className={'gpu_name'}>
                                        <span style={{ color: '#949fff' }}>
                                          {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                        </span>
                                        {card.gpuName.replace(/NVIDIA/g, '')}
                                      </span>
                                    </div>
                                    <div className={'gpu_memory'}>
                                      {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                      {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
                                      {card.memType && <span className='card__memType'> {card.memType}</span>}
                                      {!(card.memInfo || card.memType) &&
                                        card.cardType === 'nvidia' &&
                                        card.DefPwrLimit && (
                                          <span className='card__power'>
                                            {' • '}
                                            {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                          </span>
                                        )}
                                    </div>
                                    {card.memInfo && card.memType && (
                                      <div className={'gpu_memory'}>
                                        {card.cardType === 'nvidia' && card.DefPwrLimit && (
                                          <span className='card__power'>
                                            {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Col>
                                  <Col sm={12} md={9}>
                                    <Row className={'advanced_card_row'}>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                        <RangeSliderSpecial
                                          default={card.gpuClock}
                                          setValue={setCoreClockSpecial}
                                          min={-9999}
                                          max={9999}
                                          step={0}
                                          card={card}
                                          tabindex={key + +1}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                        <RangeSliderSpecial
                                          default={card.memClock}
                                          setValue={setMemoryClockSpecial}
                                          min={-9999}
                                          max={9999}
                                          step={0}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>

                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.powerLimit')}</Label>
                                        <RangeSliderSpecial
                                          default={card.powerLimit}
                                          setValue={setPowerLimitSpecial}
                                          min={0}
                                          max={999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 2}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'powerLimit')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'powerLimit')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>

                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                        <RangeSliderSpecial
                                          default={card.fan}
                                          isFan={true}
                                          setValue={setFanSpecial}
                                          min={0}
                                          max={100}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 4}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'fan')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'fan')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                    </Row>
                                  </Col>
                                  <hr />
                                </Row>
                              )
                            })
                        : ''}
                    </div>
                  </div>
                ) : null}
                {rig &&
                videoCards &&
                videoCards.length > 0 &&
                amdCards &&
                amdCards.length > 0 &&
                (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                  <div>
                    <Row className={'hide-on-mobile'}>
                      <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                      <Col sm={12} md={9}>
                        <Row className={'advanced_card_row'}>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              <Trans i18nKey='pages.workers.coreClockBR' ns={'common'}>
                                Core Clock,
                                <br />
                                Mhz
                              </Trans>
                            </Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              <Trans i18nKey='pages.workers.memoryClockBR' ns={'common'}>
                                Memory Clock,
                                <br />
                                Mhz
                              </Trans>
                            </Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              <Trans i18nKey='pages.workers.coreVDDCBR' ns={'common'}>
                                coreVDDC,
                                <br />
                                mV
                              </Trans>
                            </Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              <Trans i18nKey='pages.workers.memoryVDDCBR' ns={'common'}>
                                memVDDC,
                                <br />
                                mV
                              </Trans>
                            </Label>
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              <Trans i18nKey='pages.workers.memoryControllerBR' ns={'common'}>
                                mem Contrl,
                                <br />
                                mV
                              </Trans>
                            </Label>
                          </div>
                          {haveAMD1000 ? (
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.socFrequencyBR' ns={'common'}>
                                  SoC Frequency,
                                  <br />
                                  Mhz
                                </Trans>
                              </Label>
                            </div>
                          ) : null}
                          {haveAMD1000 ? (
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.soCVDDMaxBR' ns={'common'}>
                                  Soc VDDMax,
                                  <br />
                                  mV
                                </Trans>
                              </Label>
                            </div>
                          ) : null}

                          <div className={'form-group advanced_card_field advanced_card_field__select'}>
                            <Label>{t('pages.workers.FAN')}</Label>
                          </div>
                          <div className={'form-group advanced_card_field advanced_card_field__select'}>
                            <Label>{t('pages.workers.DPM')}</Label>
                          </div>
                          <div className={'form-group advanced_card_field  advanced_card_field__select'}>
                            <Label>{t('pages.workers.MDPM')}</Label>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <div className={'detailed_cards'}>
                      {rig && videoCards && videoCards.length > 0 && videoCards.length > 0
                        ? videoCards
                            .filter(card => card.cardType !== 'nvidia')
                            .map((card, key) => {
                              if (!card.gpuClock) card.gpuClock = ''
                              if (!card.memClock) card.memClock = ''
                              if (!card.powerLimit) card.powerLimit = ''
                              if (!card.fan) card.fan = ''
                              if (!card.coreVddc) card.coreVddc = ''
                              if (!card.memVddc) card.memVddc = ''
                              if (!card.memControlVoltage) card.memControlVoltage = ''
                              if (!card.socFrequency) card.socFrequency = ''
                              if (!card.socVDDmax) card.socVDDmax = ''
                              if (!card.dpm) card.dpm = 1
                              if (!card.mdpm) card.mdpm = 1
                              let card1000series = false
                              if (
                                card.gpuName.includes('5300') ||
                                card.gpuName.includes('5500') ||
                                card.gpuName.includes('5600') ||
                                card.gpuName.includes('5700') ||
                                card.gpuName.includes('6300') ||
                                card.gpuName.includes('6400') ||
                                card.gpuName.includes('6500') ||
                                card.gpuName.includes('6600') ||
                                card.gpuName.includes('6650') ||
                                card.gpuName.includes('6700') ||
                                card.gpuName.includes('6750') ||
                                card.gpuName.includes('6800') ||
                                card.gpuName.includes('6900') ||
                                card.gpuName.includes('6950')
                              )
                                card1000series = true
                              return (
                                <Row key={key}>
                                  <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                    <div className={'gpu_number'}>
                                      GPU{card.detected ? card.cardNum : ''} -{' '}
                                      {card.cardSlot ? card.cardSlot.substring(5) : ''} -
                                      <span className={'gpu_name'} style={{ color: '#e4909c' }}>
                                        <span style={{ color: '#949fff' }}>
                                          {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                        </span>
                                        {card.gpuName.replace(/AMD/g, '')}
                                      </span>
                                    </div>
                                    <div className={'gpu_memory'}>
                                      {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                      {card.memInfo && card.memInfo !== 'null' ? (
                                        <span className='card__memInfo card__power'>{' - ' + card.memInfo}</span>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm={12} md={9}>
                                    <Row className={'advanced_card_row'}>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                        <RangeSliderSpecial
                                          default={card.gpuClock}
                                          setValue={setCoreClockSpecial}
                                          min={-9999}
                                          max={9999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                        <RangeSliderSpecial
                                          default={card.memClock}
                                          setValue={setMemoryClockSpecial}
                                          min={-9999}
                                          max={9999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.coreVDDC')}</Label>
                                        <RangeSliderSpecial
                                          default={card.coreVddc}
                                          setValue={setCoreVddcSpecial}
                                          min={-9999}
                                          max={9999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 2}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'coreVddc')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'coreVddc')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.memoryVDDC')}</Label>
                                        <RangeSliderSpecial
                                          default={card.memVddc}
                                          setValue={setMemVddcSpecial}
                                          min={0}
                                          max={9999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 3}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'memVddc')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'memVddc')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>

                                      <div className={'form-group advanced_card_field'}>
                                        <Label className={'show-on-mobile'}>
                                          {t('pages.workers.memoryController')}
                                        </Label>
                                        <RangeSliderSpecial
                                          default={card.memControlVoltage}
                                          setValue={setmemControlVoltageSpecial}
                                          min={0}
                                          max={9999}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 4}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'memControlVoltage')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'memControlVoltage')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>

                                      {haveAMD1000 ? (
                                        <div
                                          className={
                                            card1000series
                                              ? 'form-group advanced_card_field'
                                              : 'form-group advanced_card_field hide-on-mobile'
                                          }
                                        >
                                          {card1000series ? (
                                            <>
                                              <Label className={'show-on-mobile'}>
                                                {t('pages.workers.socFrequency')}
                                              </Label>
                                              <RangeSliderSpecial
                                                default={card.socFrequency}
                                                setValue={setSocFrequencySpecial}
                                                min={0}
                                                max={9999}
                                                step={1}
                                                card={card}
                                                tabindex={key + +1 + videoCards.length * 5}
                                              />
                                              <div className={'advanced_card_field__arrows'}>
                                                <div
                                                  className={'advanced_card_field__arrows_arrow'}
                                                  onClick={e => doubleCardUp(e, card, key, 'socFrequency')}
                                                >
                                                  <i className='fas fa-chevron-double-up'></i>
                                                </div>
                                                <div
                                                  className={'advanced_card_field__arrows_arrow'}
                                                  onClick={e => doubleCardDown(e, card, key, 'socFrequency')}
                                                >
                                                  <i className='fas fa-chevron-double-down'></i>
                                                </div>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                      {haveAMD1000 ? (
                                        <div
                                          className={
                                            card1000series
                                              ? 'form-group advanced_card_field'
                                              : 'form-group advanced_card_field hide-on-mobile'
                                          }
                                        >
                                          {card1000series ? (
                                            <>
                                              <Label className={'show-on-mobile'}>{t('pages.workers.soCVDDMax')}</Label>
                                              <RangeSliderSpecial
                                                default={card.socVDDmax}
                                                setValue={setsocVDDmaxSpecial}
                                                min={0}
                                                max={9999}
                                                step={1}
                                                card={card}
                                                tabindex={key + +1 + videoCards.length * 6}
                                              />
                                              <div className={'advanced_card_field__arrows'}>
                                                <div
                                                  className={'advanced_card_field__arrows_arrow'}
                                                  onClick={e => doubleCardUp(e, card, key, 'socVDDmax')}
                                                >
                                                  <i className='fas fa-chevron-double-up'></i>
                                                </div>
                                                <div
                                                  className={'advanced_card_field__arrows_arrow'}
                                                  onClick={e => doubleCardDown(e, card, key, 'socVDDmax')}
                                                >
                                                  <i className='fas fa-chevron-double-down'></i>
                                                </div>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}

                                      <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                        <RangeSliderSpecial
                                          default={card.fan}
                                          setValue={setFanSpecial}
                                          isFan={true}
                                          min={0}
                                          max={100}
                                          step={1}
                                          card={card}
                                          tabindex={key + +1 + videoCards.length * 7}
                                        />
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'fan')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'fan')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.DPM')}</Label>
                                        <select
                                          onChange={event => setDPMVddcSpecial(event, card)}
                                          value={card.dpm}
                                          tabIndex={key + +1 + videoCards.length * 8}
                                          className={'form-control'}
                                        >
                                          <option value={1}>1</option>
                                          <option value={2}>2</option>
                                          {!card1000series && (
                                            <>
                                              <option value={3}>3</option>
                                              <option value={4}>4</option>
                                              <option value={5}>5</option>
                                              <option value={6}>6</option>
                                              <option value={7}>7</option>
                                            </>
                                          )}
                                        </select>
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'dpm')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'dpm')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                        <Label className={'show-on-mobile'}>{t('pages.workers.MDPM')}</Label>
                                        <select
                                          className={'form-control'}
                                          onChange={event => setMDPMVddcSpecial(event, card)}
                                          value={card.mdpm}
                                          tabIndex={key + +1 + videoCards.length * 9}
                                        >
                                          <option value={1}>1</option>
                                          <option value={2}>2</option>
                                        </select>
                                        <div className={'advanced_card_field__arrows'}>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardUp(e, card, key, 'mdpm')}
                                          >
                                            <i className='fas fa-chevron-double-up'></i>
                                          </div>
                                          <div
                                            className={'advanced_card_field__arrows_arrow'}
                                            onClick={e => doubleCardDown(e, card, key, 'mdpm')}
                                          >
                                            <i className='fas fa-chevron-double-down'></i>
                                          </div>
                                        </div>
                                      </div>
                                    </Row>
                                  </Col>
                                  <hr />
                                </Row>
                              )
                            })
                        : ''}
                    </div>
                  </div>
                ) : null}
              </Form>
            </TabPanel>
            <TabPanel>
              <Form className={'overclocking-additional'}>
                <div className={'description'}>{t('pages.workers.additionalOverclockingParameters')}</div>
                <div>
                  {rig && amdCards && amdCards.length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'rotateLogs'}
                          type={'checkbox'}
                          checked={amdmemorytweak}
                          onChange={() => {
                            setAmdmemorytweak(!amdmemorytweak)
                          }}
                        />
                        {t('pages.workers.amdmemorytweak')}
                      </Label>
                      <Button
                        color={'primary'}
                        disabled={!amdmemorytweak}
                        onClick={() => {
                          setAmdmemorytweakEdit(true)
                        }}
                      >
                        {t('pages.workers.edit')}
                      </Button>
                    </div>
                  ) : null}
                  {rig &&
                  nvidiaCards &&
                  nvidiaCards.length > 0 &&
                  rig.videoCards.filter(card => card.gpuName.includes('1080')).length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'rotateLogs'}
                          type={'checkbox'}
                          checked={ohGodAnETHlagementPill}
                          onChange={() => {
                            setOhGodAnETHlagementPill(!ohGodAnETHlagementPill)
                          }}
                        />
                        {t('pages.workers.OhGodAnETHlagementPill')}
                      </Label>
                      <Button
                        color={'primary'}
                        disabled={!ohGodAnETHlagementPill}
                        onClick={() => {
                          setOhGodAnETHlagementPillEdit(true)
                        }}
                      >
                        {t('pages.workers.edit')}
                      </Button>
                    </div>
                  ) : null}
                  {rig && nvidiaCards && nvidiaCards.length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'p0'}
                          type={'checkbox'}
                          checked={p0}
                          onChange={() => {
                            setP0(!p0)
                          }}
                        />
                        {t('pages.workers.P0mode')}
                      </Label>
                    </div>
                  ) : null}

                  <div className={'cardTweak'}>
                    <Label className={'checkboxLabel'}>
                      <input
                        name={'rotateLogs'}
                        type={'checkbox'}
                        checked={autoTune}
                        onChange={() => {
                          setAutoTune(!autoTune)
                        }}
                      />
                      {t('pages.workers.autoTune')}
                    </Label>
                    <Button
                      color={'primary'}
                      disabled={!autoTune}
                      onClick={() => {
                        setAutoTuneEdit(true)
                      }}
                    >
                      {t('pages.workers.edit')}
                    </Button>
                  </div>
                </div>
                <Row className={'card_algorithm'}>
                  <Col xs={12} sm={4} md={3}>
                    <Label> {t('pages.workers.algorithm')}</Label>
                    <SelectWithCryptoImages
                      name={'algo'}
                      placeholder={t('inputs.selectAlgo')}
                      hideSelectedOptions
                      classNamePrefix={'form_control_react_select'}
                      onchange={setAlgo}
                      value={algorithm}
                      options={props.algoCoinsList.map(algo => {
                        return {
                          ...algo,
                          label: algo.algo,
                          value: algo.algo,
                        }
                      })}
                    />
                  </Col>
                </Row>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: amdmemorytweakEdit && amdmemorytweak ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='amdmemorytweak'
                        value={amdmemorytweakString2}
                        onChange={e => {
                          setAmdmemorytweakString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveAmdmemorytweak}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetAmdmemorytweak}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: ohGodAnETHlagementPillEdit && ohGodAnETHlagementPill ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='ohGodAnETHlagementPilltweak'
                        value={ohGodAnETHlagementPillString2}
                        onChange={e => {
                          setOhGodAnETHlagementPillString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveOhGodAnETHlagementPill}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetOhGodAnETHlagementPill}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: autoTuneEdit && autoTune ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='ohGodAnETHlagementPilltweak'
                        value={autoTuneEditString2}
                        onChange={e => {
                          setAutoTuneEditString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveAutoTune}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetAutoTune}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </TabPanel>
          </Tabs>
          <div className={'actions_card'}>
            <Row>
              <Col sm={4} className={'radio_buttons'}>
                <div>
                  <input
                    type='radio'
                    id='apply_action1'
                    name='apply_action'
                    value='on_fly'
                    onChange={e => setApplyAction(e)}
                    checked={action === 'on_fly'}
                  />
                  <Label htmlFor='apply_action1'>{t('pages.workers.applyFly')}</Label>
                </div>
                <div>
                  <input
                    type='radio'
                    id='apply_action2'
                    name='apply_action'
                    value='restart'
                    onChange={e => setApplyAction(e)}
                    checked={action === 'restart'}
                  />
                  <Label htmlFor='apply_action2'>{t('pages.workers.restartAndApply')}</Label>
                </div>
                <div>
                  <input
                    type='radio'
                    id='apply_action3'
                    name='apply_action'
                    value='reboot'
                    onChange={e => setApplyAction(e)}
                    checked={action === 'reboot'}
                  />
                  <Label htmlFor='apply_action3'>{t('pages.workers.rebootAndApply')}</Label>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => checkOverclockingErrors('overclocking')}
            disabled={!advancedPage && locked}
          >
            {t('pages.workers.apply')}
          </Button>
          <Button color='success' onClick={() => checkOverclockingErrors('profile')} disabled={!advancedPage && locked}>
            {t('pages.workers.saveAs')}
          </Button>
          <Button color='secondary' onClick={toggle}>
            {t('pages.workers.cancel')}
          </Button>
        </ModalFooter>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.profiles.algoCoinsList,
  overclockingData: store.rigs.overclockingData,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
})

const mapDispatchToProps = {
  overclocking: actions.overclocking,
  overclockingEach: actions.overclockingEach,
  saveOverclockingProfile: actionsProfiles.saveOverclockingProfile,
  setOverclockingProfilesList: actionsProfiles.setOverclockingProfilesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverclockingTab)
