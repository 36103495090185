import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import * as actions from '../actions'

const PowerManagementTab = ({ powerConsumption, setPowerConsumption }) => {
  const [generic_pdu_device_management, set_generic_pdu_device_management] = useState(false)
  const { t } = useTranslation('common')

  const [allow_to_assign_pdu_and_outlet, set_allow_to_assign_pdu_and_outlet] = useState(false)
  const [worker_default_manual_reboot_duration_time, set_worker_default_manual_reboot_duration_time] = useState(false)
  const [worker_default_automatic_reboot_duration_time, set_worker_default_automatic_reboot_duration_time] =
    useState(false)

  const [worker_default_manual_reboot_duration_time_sec, set_worker_default_manual_reboot_duration_time_sec] =
    useState('')
  const [worker_default_automatic_reboot_duration_time_sec, set_worker_default_automatic_reboot_duration_time_sec] =
    useState('')

  const [general_power_consumption_adjustments, set_general_power_consumption_adjustments] = useState(true)
  const [general_power_consumption_adjustments_input, set_general_power_consumption_adjustments_input] = useState('')

  const [worker_default_power_consumption_adjustments, set_worker_default_power_consumption_adjustments] =
    useState(powerConsumption)

  const [timeoutId, setTimeoutId] = useState(null)

  const debouncedSetPowerConsumption = useCallback(
    value => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      const newTimeoutId = setTimeout(() => {
        setPowerConsumption({ powerConsumption: +value })
      }, 1000)
      setTimeoutId(newTimeoutId)
    },
    [setPowerConsumption, timeoutId],
  )

  const handlePowerConsumptionChange = e => {
    const value = e.target.value
    set_worker_default_power_consumption_adjustments(value)
    debouncedSetPowerConsumption(value)
  }

  return (
    <div className={'settings__power_management'}>
      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.genericPDUDeviceManagement')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => set_generic_pdu_device_management(false)}
            checked={false}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: generic_pdu_device_management ? 1 : 0.2 }}>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={allow_to_assign_pdu_and_outlet && generic_pdu_device_management}
                onChange={() => set_allow_to_assign_pdu_and_outlet(!allow_to_assign_pdu_and_outlet)}
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.allowAssignPDUOutlet')}
            </Label>
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={worker_default_manual_reboot_duration_time && generic_pdu_device_management}
                onChange={() =>
                  set_worker_default_manual_reboot_duration_time(!worker_default_manual_reboot_duration_time)
                }
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.workerDefaultManualReboot')}
            </Label>
            <Input
              placeholder={'20'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={worker_default_manual_reboot_duration_time_sec}
              onChange={e => set_worker_default_manual_reboot_duration_time_sec(e.target.value)}
              disabled={!generic_pdu_device_management || !worker_default_manual_reboot_duration_time}
            />
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={worker_default_automatic_reboot_duration_time && generic_pdu_device_management}
                onChange={() =>
                  set_worker_default_automatic_reboot_duration_time(!worker_default_automatic_reboot_duration_time)
                }
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.workerDefaultAutomaticReboot')}
            </Label>
            <Input
              placeholder={'20'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={worker_default_automatic_reboot_duration_time_sec}
              onChange={e => set_worker_default_automatic_reboot_duration_time_sec(e.target.value)}
              disabled={!generic_pdu_device_management || !worker_default_automatic_reboot_duration_time}
            />
          </div>
        </div>

        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.generalPowerConsumptionAdjustments')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => set_general_power_consumption_adjustments(!general_power_consumption_adjustments)}
            checked={general_power_consumption_adjustments}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: general_power_consumption_adjustments ? 1 : 0.2 }}>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={
                  worker_default_power_consumption_adjustments > 0 || general_power_consumption_adjustments_input
                }
                onChange={() => {
                  const newValue = !(
                    worker_default_power_consumption_adjustments > 0 || general_power_consumption_adjustments_input
                  )
                  set_general_power_consumption_adjustments_input(newValue)
                  if (!newValue) {
                    // if turning off
                    setPowerConsumption({ powerConsumption: 0 })
                    set_worker_default_power_consumption_adjustments(0)
                  }
                }}
                disabled={!general_power_consumption_adjustments}
              />
              {t('pages.settings.addPowerConsumptionToWorkers')}
            </Label>
            <Input
              placeholder={'0'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={worker_default_power_consumption_adjustments}
              onChange={handlePowerConsumptionChange}
              disabled={!general_power_consumption_adjustments}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  powerConsumption: store.settings.powerConsumption,
})

const mapDispatchToProps = {
  setPowerConsumption: actions.setPowerConsumption,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerManagementTab)
