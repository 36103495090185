import React from 'react'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import GeneralSettingsTab from '../components/GeneralSettingsTab'
import WatchdogSettingsTab from '../components/WatchdogSettingsTab'
import PowerManagementTab from '../components/PowerManagementTab'
import AutoFanTab from '../components/AutoFanTab'
import { useTranslation } from 'react-i18next'

const SettingsPage = () => {
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.settings.settings')}</h3>
          </div>
        </div>
        <div className={'settings__body'}>
          <Tabs>
            <TabList className={'card_tabs'}>
              <Tab>{t('pages.settings.general')}</Tab>
              <Tab>{t('pages.settings.watchdog')}</Tab>
              <Tab>{t('pages.settings.powerManagement')}</Tab>
              <Tab>{t('pages.settings.SettingsAutoFAN')}</Tab>
            </TabList>

            <TabPanel>
              <GeneralSettingsTab />
            </TabPanel>

            <TabPanel>
              <WatchdogSettingsTab />
            </TabPanel>

            <TabPanel>
              <PowerManagementTab />
            </TabPanel>

            <TabPanel>
              <AutoFanTab />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, null)(SettingsPage)
