import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, FormGroup, Label } from 'reactstrap'
import { connect } from 'react-redux'
import * as actions from '../../../../RigsData/actions'

const SelectVBIOSModal = ({
  workerId,
  isOpen,
  toggle,
  onSelectVBIOS,
  onSelectFile,
  saveVbiosFile,
  vbiosList,
  getVbiosList,
  deleteVbiosFile,
  updateVbiosFile,
}) => {
  const [vbiosFiles, setVbiosFiles] = useState([])
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [editingFile, setEditingFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    getVbiosList({ rigId: +workerId })
  }, [getVbiosList, workerId])

  useEffect(() => {
    if (vbiosList) {
      setVbiosFiles(vbiosList.items || [])
    }
  }, [vbiosList])

  const handleFileUpload = async () => {
    if (selectedFile && fileName.trim()) {
      try {
        // Convert the file to the expected Upload type
        const file = new File([selectedFile], selectedFile.name, { type: selectedFile.type })

        const fileId = await saveVbiosFile({
          rigId: workerId,
          file: file, // Pass the File object directly
          name: fileName,
        })

        const newFile = {
          id: fileId,
          name: fileName,
          fileName: selectedFile.name,
        }
        setVbiosFiles([...vbiosFiles, newFile])
        setFileName('')
        setSelectedFile(null)
        setIsUploadModalOpen(false)
      } catch (error) {
        console.error('Failed to upload file:', error)
        // You might want to show an error message to the user here
      }
    }
  }

  const handleFileSelect = event => {
    const file = event.target.files[0]
    if (file) {
      setFileName(file.name.split('.')[0]) // Default name to filename without extension
      setSelectedFile(file)
    }
  }

  const handleNameChange = event => {
    setFileName(event.target.value)
  }

  const handleDelete = id => {
    deleteVbiosFile({ id })
    setVbiosFiles(vbiosFiles.filter(file => file.id !== id))
  }

  const handleRename = (id, newName) => {
    updateVbiosFile({ id, name: newName })
    setVbiosFiles(vbiosFiles.map(file => (file.id === id ? { ...file, name: newName } : file)))
    setEditingFile(null)
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        <div className='position-relative' style={{ marginBottom: '60px' }}>
          <ModalHeader toggle={toggle} className='position-relative'>
            <div className='w-100 text-center position-absolute' style={{ left: 0 }}>
              <h3 className='mb-0'>Select VBIOS</h3>
            </div>
          </ModalHeader>
          <Button
            color='primary'
            size='sm'
            style={{
              position: 'absolute',
              right: '15px',
              top: '15px',
              zIndex: 1,
            }}
            onClick={() => setIsUploadModalOpen(true)}
          >
            Upload
          </Button>
        </div>

        <ModalBody>
          <Table dark striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Filename</th>
                <th style={{ width: '150px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(vbiosFiles || []).map(file => {
                return (
                  <tr key={file.id}>
                    <td>
                      {editingFile === file.id ? (
                        <Input
                          type='text'
                          defaultValue={file.name}
                          onBlur={e => handleRename(file.id, e.target.value)}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              handleRename(file.id, e.target.value)
                            }
                          }}
                          autoFocus
                        />
                      ) : (
                        file.name
                      )}
                    </td>
                    <td>{file.fileName}</td>
                    <td>
                      <div className='d-flex justify-content-start'>
                        <div
                          className='actions__item actions__item_rig'
                          tooltip='Edit'
                          flow='left'
                          onClick={() => setEditingFile(file.id)}
                        >
                          <i className='fa fa-pencil'></i>
                        </div>
                        <div
                          className='actions__item actions__item_rig'
                          tooltip='Flash'
                          flow='left'
                          onClick={() => {
                            onSelectVBIOS(file.name)
                            onSelectFile(file)
                            toggle()
                          }}
                        >
                          <i className='fa fa-bolt' style={{ color: file.flashed ? '#ffd700' : 'inherit' }}></i>
                        </div>
                        <div
                          className='actions__item actions__item_rig'
                          tooltip='Delete'
                          flow='left'
                          onClick={() => handleDelete(file.id)}
                        >
                          <i className='fa fa-trash'></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <div className='d-flex justify-content-end w-100'>
            <Button color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isUploadModalOpen}
        toggle={() => {
          setIsUploadModalOpen(false)
          setFileName('')
          setSelectedFile(null)
        }}
        size='sm'
      >
        <ModalHeader toggle={() => setIsUploadModalOpen(false)} className='position-relative'>
          <div className='w-100 text-center position-absolute' style={{ left: 0 }}>
            <h3 className='mb-0'>Upload VBIOS</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='vbiosFile'>Select File</Label>
            <Input
              id='vbiosFile'
              type='file'
              accept='.rom,.bin,.fw, .ifr'
              onChange={handleFileSelect}
              style={{ marginBottom: '20px' }}
            />
          </FormGroup>
          <FormGroup>
            <Label for='vbiosName'>VBIOS Name</Label>
            <Input
              id='vbiosName'
              type='text'
              value={fileName}
              onChange={handleNameChange}
              placeholder='Enter VBIOS name'
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleFileUpload} disabled={!selectedFile || !fileName.trim()}>
            Upload
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              setFileName('')
              setSelectedFile(null)
              setIsUploadModalOpen(false)
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  vbiosList: state.rigs.vbiosList,
})

const mapDispatchToProps = {
  saveVbiosFile: actions.saveVbiosFile,
  getVbiosList: actions.getVbiosList,
  deleteVbiosFile: actions.deleteVbiosFile,
  updateVbiosFile: actions.updateVbiosFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectVBIOSModal)
