import { types } from './types'

import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const SET_AUTOFAN = `
  query autofan($type: String, $rigsIds: String) {
    autofan(type: $type, rigsIds: $rigsIds) {
      id
      userId
      minFanSpeed,
      maxFanSpeed,
      targetMemTemp,
      targetTemp,
      status,
      keepTargetTemp,
      coldCase
    }
  }
`

const SET_GENERAL_SETTINGS = `
  query generalSettings($type: String) {
    generalSettings(type: $type) {
      id
      critTemp
      highTemp
      critTempAction
      la
      laAction
      laBool
      lowHashBool
      lowHashTime
      lowHashAction
      zeroHashBool
      zeroHashTime
      zeroHashAction
      hashrateWatchdog
      powerConsumption
    }
  }
`

const SET_LOCAL_SETTINGS = `
  query rigsCritTemp($rigId: String!) {
    rigsCritTemp(rigId: $rigId) {
      action
      critTemp
      highTemp
    }
  }
`

const DEFOULT_GENERAL_SETTINGS = `
  mutation defoultGeneralSettings($id: Int!) {
    defoultGeneralSettings(id: $id)
  }
`

const DEFOULT_LOCAL_SETTINGS = `
  mutation defoultLocalSettings($rigIds: String!) {
    defoultLocalSettings(rigIds: $rigIds) {
      critTemp
      critTempAction
      highTemp
    }
  }
`

const UPDATE_GENERAL_SETTINGS = `
mutation updateGeneralSettings($critTemp: Int, $highTemp: Int, $critLowHash: Int, $lowHash: Int, $resInterval: Int, $critTempAction: String, $la: String, $laAction: String, $hashrateWatchdog: Boolean, $laBool: Boolean, $lowHashTime: Int, $lowHashAction: String, $lowHashBool: Boolean, $zeroHashTime: Int, $zeroHashAction: String, $zeroHashBool: Boolean, $updateAllRigs: Boolean) {
    updateGeneralSettings(critTemp: $critTemp, highTemp: $highTemp, critLowHash: $critLowHash, lowHash: $lowHash, resInterval: $resInterval, critTempAction: $critTempAction, la: $la, laAction: $laAction, hashrateWatchdog: $hashrateWatchdog, laBool: $laBool, lowHashTime: $lowHashTime, lowHashAction: $lowHashAction, lowHashBool: $lowHashBool, zeroHashTime: $zeroHashTime, zeroHashAction: $zeroHashAction, zeroHashBool: $zeroHashBool, updateAllRigs: $updateAllRigs)
  }
`

const UPDATE_LOCAL_SETTINGS = `
mutation updateLocalSettings($critTemp: Int, $highTemp: Int, $critTempAction: String, $rigIds: String) {
    updateLocalSettings(critTemp: $critTemp, highTemp: $highTemp, rigIds: $rigIds, critTempAction: $critTempAction)
  }
`

const ACTION_AUTOFAN = `
  mutation createAutofan($type: String!, $rigsIds: String, $minFanSpeed: Int, $maxFanSpeed: Int, $targetTemp: Int, $targetMemTemp: Int, $keepTargetTemp: Boolean, $status: Boolean, $coldCase: Boolean, $autofanId: Int) {
    createAutofan(type: $type, rigsIds: $rigsIds, minFanSpeed: $minFanSpeed, maxFanSpeed: $maxFanSpeed, targetTemp: $targetTemp, targetMemTemp: $targetMemTemp, keepTargetTemp: $keepTargetTemp, status: $status, coldCase: $coldCase, autofanId: $autofanId) {
      id
      userId
      minFanSpeed,
      maxFanSpeed,
      targetMemTemp,
      targetTemp,
      status,
      keepTargetTemp,
      coldCase
    }
  }
`

const SET_POWER_CONSUMPTION = `
  mutation setPowerConsumption($powerConsumption: Int!) {
    setPowerConsumption(powerConsumption: $powerConsumption)
  }
`

export const setPowerConsumption =
  ({ powerConsumption }) =>
  async dispatch => {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_POWER_CONSUMPTION,
      variables: { powerConsumption },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty')
    }

    const { setPowerConsumption } = data
    if (!setPowerConsumption) {
      throw new Error("Can't set power consumption!")
    }

    dispatch({
      type: types.SET_POWER_CONSUMPTION,
      data: powerConsumption,
    })

    dispatch(addAlert('Power consumption have been updated successfully!', 'success'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }

export const deleteLocalSettings = () => async dispatch => {
  dispatch({
    type: types.DEL_LOCAL_SETTINGS,
  })
}

export const setLocalSettings =
  ({ rigId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_LOCAL_SETTINGS,
        variables: { rigId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { rigsCritTemp } = data
      if (!rigsCritTemp) {
        throw new Error("Can't Get Settings!")
      }

      dispatch({
        type: types.SET_LOCAL_SETTINGS,
        data: rigsCritTemp,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const toDefoultLocalSettings =
  ({ rigIds }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DEFOULT_LOCAL_SETTINGS,
        variables: { rigIds },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { defoultLocalSettings } = data
      if (!defoultLocalSettings) {
        throw new Error("Can't Update Settings!")
      }

      dispatch({
        type: types.DEFOULT_LOCAL_SETTINGS,
        data: defoultLocalSettings,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      dispatch(addAlert('Settings have been updated successfully!', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const toDefoultGeneralSettings =
  ({ id }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DEFOULT_GENERAL_SETTINGS,
        variables: { id },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { defoultGeneralSettings } = data
      if (!defoultGeneralSettings) {
        throw new Error("Can't Update Settings!")
      }

      dispatch({
        type: types.DEFOULT_GENERAL_SETTINGS,
        data: { critTemp: 85, highTemp: 69, critTempAction: 'Stop Miner' },
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      dispatch(addAlert('Settings have been updated successfully!', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const updateLocalSettings =
  ({ critTemp, highTemp, rigIds, critTempAction }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_LOCAL_SETTINGS,
        variables: { critTemp, highTemp, rigIds, critTempAction },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { updateLocalSettings } = data
      if (!updateLocalSettings) {
        throw new Error("Can't Update Settings!")
      }

      dispatch({
        type: types.UPDATE_LOCAL_SETTINGS,
        data: { critTemp, critTempAction, highTemp },
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Settings have been updated successfully!', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const updateGeneralSettings =
  ({
    critTemp,
    highTemp,
    critLowHash,
    lowHash,
    resInterval,
    critTempAction,
    la,
    laAction,
    laBool,
    lowHashTime,
    lowHashBool,
    lowHashAction,
    zeroHashTime,
    zeroHashBool,
    zeroHashAction,
    hashrateWatchdog,
    updateAllRigs,
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      if (hashrateWatchdog && !laBool && !lowHashBool && !zeroHashBool) {
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })
        return true
      }

      if (zeroHashTime && zeroHashTime <= 1) {
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })

        return dispatch(addAlert('Zero hashrate delay time must be > 1 (min)', 'danger'))
      }

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_GENERAL_SETTINGS,
        variables: {
          critTemp,
          highTemp,
          critLowHash,
          lowHash,
          resInterval,
          critTempAction,
          la,
          laAction,
          laBool,
          lowHashBool,
          lowHashAction,
          zeroHashTime: +zeroHashTime,
          lowHashTime: +lowHashTime,
          zeroHashBool,
          zeroHashAction,
          hashrateWatchdog,
          updateAllRigs,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { updateGeneralSettings } = data
      if (!updateGeneralSettings) {
        throw new Error("Can't Update Settings!")
      }

      dispatch({
        type: types.UPDATE_GENERAL_SETTINGS,
        data: {
          critTemp,
          highTemp,
          critLowHash,
          lowHash,
          resInterval,
          critTempAction,
          la,
          laAction,
          hashrateWatchdog,
          updateAllRigs,
        },
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Settings have been updated successfully!', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const actionAutofan =
  ({
    type,
    rigsIds,
    autofanId,
    minFanSpeed,
    maxFanSpeed,
    targetMemTemp,
    targetTemp,
    status,
    keepTargetTemp,
    coldCase,
  }) =>
  async dispatch => {
    try {
      if (minFanSpeed > 100 || maxFanSpeed > 100 || targetMemTemp > 100 || targetTemp > 100)
        throw new Error('Not valid data!')

      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: ACTION_AUTOFAN,
        variables: {
          type,
          autofanId,
          rigsIds,
          minFanSpeed,
          maxFanSpeed,
          targetMemTemp,
          targetTemp,
          status,
          keepTargetTemp,
          coldCase,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { createAutofan } = data
      if (!createAutofan) {
        throw new Error("Can't action autofan!")
      }

      dispatch({
        type: types.ACTION_AUTOFAN,
        data: createAutofan,
      })

      dispatch(addAlert('Autofan parameters have been applied successfully!', 'success'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
    }
  }

export const setGeneralSettings =
  ({ type }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_GENERAL_SETTINGS,
        variables: { type },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { generalSettings } = data
      if (!generalSettings) {
        throw new Error("Can't Get Settings!")
      }

      dispatch({
        type: types.SET_GENERAL_SETTINGS,
        data: generalSettings,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const setAutofan =
  ({ type, rigsIds }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_AUTOFAN,
        variables: { type, rigsIds },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { autofan } = data
      if (!autofan) {
        throw new Error("Can't get autofan")
      }

      dispatch({
        type: types.SET_AUTOFAN,
        data: autofan,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }
