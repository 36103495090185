import { types } from './types'

const initState = {
  forwardAutofan: false,
  critTemp: 85,
  critTempAction: 'Stop Mining',
  autofan: {},
  localRigsSettings: {},
  powerConsumption: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_POWER_CONSUMPTION: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        powerConsumption: data.powerConsumption,
      }
    }

    case types.SET_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.action,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEL_LOCAL_SETTINGS: {
      const stateCopy = { ...state }
      const localSettings = {}
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.SET_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        id: data.id,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
        critTempAction: data.critTempAction,
        la: data.la,
        laAction: data.laAction,
        laBool: data.laBool,
        lowHashBool: data.lowHashBool,
        lowHashTime: data.lowHashTime,
        lowHashAction: data.lowHashAction,
        zeroHashBool: data.zeroHashBool,
        zeroHashTime: data.zeroHashTime,
        zeroHashAction: data.zeroHashAction,
        hashrateWatchdog: data.hashrateWatchdog,
        powerConsumption: data.powerConsumption,
      }
    }

    case types.UPDATE_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        critTemp: data.critTemp ? data.critTemp : stateCopy.critTemp,
        highTemp: data.highTemp ? data.highTemp : stateCopy.highTemp,
        critTempAction: data.critTempAction ? data.critTempAction : stateCopy.critTempAction,
        la: data.la ? data.la : stateCopy.la,
        laAction: data.laAction ? data.laAction : stateCopy.laAction,
        laBool: data.laBool ? data.laBool : stateCopy.laBool,
        lowHashBool: data.lowHashBool ? data.lowHashBool : stateCopy.lowHashBool,
        lowHashTime: data.lowHashTime ? data.lowHashTime : stateCopy.lowHashTime,
        lowHashAction: data.lowHashAction ? data.lowHashAction : stateCopy.lowHashAction,
        zeroHashBool: data.zeroHashBool ? data.zeroHashBool : stateCopy.zeroHashBool,
        zeroHashTime: data.zeroHashTime ? data.zeroHashTime : stateCopy.zeroHashTime,
        zeroHashAction: data.zeroHashAction ? data.zeroHashAction : stateCopy.zeroHashAction,
        hashrateWatchdog: data.hashrateWatchdog ? data.hashrateWatchdog : stateCopy.hashrateWatchdog,
        updateAllRigs: data.updateAllRigs ? data.updateAllRigs : stateCopy.updateAllRigs,
      }
    }

    case types.UPDATE_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.critTempAction,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEFOULT_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.critTempAction,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEFOULT_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
        critTempAction: data.critTempAction,
      }
    }

    case types.SET_AUTOFAN: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        autofan: data,
      }
    }

    case types.ACTION_AUTOFAN: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        autofan: data,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
